import { decorate, action, observable, runInAction } from 'mobx';
import axios from 'axios';

import productCategories from 'data/productCategories';
import { CALL_REQUEST } from 'api_routes';


class CallRequestStore {
  products = productCategories;

  SUBMITTED = 'submitted';

  PENDING = 'pending';

  ERROR = 'error';

  status = null;

  async submitRequest(data) {
    this.status = this.PENDING;

    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}${CALL_REQUEST}`;
      // const response = await axios.post(url, data);
      await new Promise((resolve, reject) => resolve(null));

      runInAction(() => {
        this.status = this.SUBMITTED;
      });
    } catch (error) {
      runInAction(() => {
        this.status = this.ERROR;
      });
    }
  }
}

export default decorate(CallRequestStore, {
  products: observable,
  status: observable,
  submitRequest: action,
});
