import { decorate, action, computed } from 'mobx';

import initialValues from 'data/businessInitialValues';

import LookupStore from './lookupStore';
import QuoteStore from './quoteStore';


class BusinessStore {
  constructor(APIRoutes) {
    this.APIRoutes = APIRoutes;
    this.lookups = new LookupStore(this);
    this.quote = new QuoteStore(this, initialValues);
  }

  postQuote(data) {
    return this.quote.post(this.APIRoutes.QUOTE, data);
  }

  get formData() {
    return this.quote.data;
  }
}

export default decorate(BusinessStore, {
  postQuote: action,
  formData: computed,
});
