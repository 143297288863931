/**
 * Component to handle inject of MobX stores and window scroll reset for
 * routed components.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';


const PropsRoute = ({ component, stores, productStore, scroll, ...props }) => {
  let routeComponent = component;
  const location = useLocation();

  useEffect(() => (scroll ? window.scrollTo(0, 0) : null),
    [scroll, location.pathname]);

  if (stores.length > 0 || productStore) {
    const injected = [...stores, productStore].filter(i => i !== null);
    routeComponent = inject(injected)(observer(component));
  }

  return (
    <Route
      {...props}
      render={routeProps => (
        React.createElement(routeComponent, {
          ...routeProps,
          ...props,
          productStore,
        })
      )}
    />
  );
};

PropsRoute.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.string),
  exact: PropTypes.bool,
  component: PropTypes.elementType.isRequired,
  productStore: PropTypes.string,
  scroll: PropTypes.bool,
};

PropsRoute.defaultProps = {
  stores: [],
  exact: true,
  productStore: null,
  scroll: true,
};

export default PropsRoute;
