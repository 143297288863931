import React from 'react';


function ModalContent() {
  return (
    <>
      <h6 className="text-primary font-weight-bold">
        Comprehensive
      </h6>
      <p className="mb-6">
        Fully comprehensive car insurance is the highest level of cover you
        can take out for your car. It provides coverage for you and your
        vehicle as well as other road users.
      </p>
      <h6 className="text-primary font-weight-bold">
        Third Party
      </h6>
      <p className="mb-6">
        Third-party car insurance covers you if you damage someone else’s
        property or injure them while driving, and will cover your passengers
        too.
      </p>
      <h6 className="text-primary font-weight-bold">
        Third Party, Fire & Theft
      </h6>
      <p className="m-0">
        Third party, fire and theft car insurance covers any damage to a
        person, their car or their property if you have an accident while
        driving. It also covers your car in case it’s stolen or gets damaged
        or destroyed in a fire.
      </p>
    </>
  );
}

export default ModalContent;
