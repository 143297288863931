import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import ROUTES from 'routes';

import Oops from 'images/oops.svg';
import QuickLinks from 'components/QuickLinks';
import Page from 'components/Page';


function NotFound() {
  return (
    <Page
      title="Not Found"
      className="d-flex mt-12 mt-md-17 align-items-center"
    >
      <Container>
        <Row className="flex-grow-1 align-content-center my-4 my-md-12">
          <Col className="text-center">
            <Row>
              <Col>
                <img src={Oops} alt="Oops!" height="100" />
              </Col>
            </Row>
            <Row className="my-3 my-md-8">
              <Col md={8} className="m-auto">
                <h4>
                  We can&apos;t find exactly what you requested, but perhaps one
                  of these may be of interest.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <QuickLinks />
              </Col>
            </Row>
            <Row className="mt-3 my-md-8">
              <Col>
                <Link
                  to={ROUTES.root}
                  className="btn btn-secondary px-10 text-decoration-none"
                >
                  Back to Homepage
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default NotFound;
