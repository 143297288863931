import StoryblokClient from 'storyblok-js-client';
import { reduce } from 'lodash';
import { observable, action, decorate, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';
import parse from 'html-react-parser';

import STORIES from 'storybloks';

class BlokStore {
  client = new StoryblokClient({
    accessToken: process.env.REACT_APP_STORYBLOK_TOKEN,
  });

  clientOptions = {
    version: process.env.REACT_APP_STORYBLOK_VERSION || 'published',
  };

  IS_LOADED = 1;

  IS_FETCHING = 0;

  IS_FETCH_ERROR = -2;

  stories = reduce(STORIES, (result, value) => {
    result[value] = { status: null, content: {} };
    return result;
  }, {});

  async fetchStory(story) {
    // Skip calling Storyblok API if story is already loaded or is pending
    const isLoaded = this.stories[story].status === this.IS_LOADED;
    const isFetching = this.stories[story].status === this.IS_FETCHING;

    if (this.stories[story] && (isLoaded || isFetching)) {
      return this.stories[story].content;
    }

    try {
      const { data } = await this.client.get(`cdn/stories/${story}`, this.clientOptions);

      runInAction(() => {
        this.stories[story] = {
          status: this.IS_LOADED,
          content: data.story.content,
        };
      });
    } catch (error) {
      runInAction(() => {
        this.stories[story].status = this.IS_FETCH_ERROR;
      });
    }

    return this.stories[story].content;
  }

  // Return a block for a story
  getBlock = computedFn(function getBlockComponent(story) {
    this.fetchStory(story);

    return (key, defaultValue = null) => {
      try {
        const block = this.stories[story].content[key];

        // Storyblok returns empty string for text values instead of null so
        // count that as undefined
        if (block === undefined || block === '') {
          return defaultValue;
        }

        return block;
      } catch {
        return defaultValue;
      }
    };
  })

  render(component) {
    const content = this.client.richTextResolver.render(component);
    return parse(content);
  }
}

export default decorate(BlokStore, {
  stories: observable,
  fetchStory: action,
  getStory: action,
});
