import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import PageView from 'components/PageView';
import Interstitial from 'components/Interstitial';
import NotFound from 'components/NotFound';
import Route from 'components/PropsRoute';
import ErrorBoundary from 'components/ErrorBoundary';
import InternalError from 'components/InternalError';
import MotorModalContent from 'containers/MotorInsurance/ModalContent';

import Navigation from 'containers/Navigation';
import Footer from 'components/Footer';
import ROUTES from 'routes';

const Homepage = lazy(() => import('containers/Homepage'));

const MotorInsurance = lazy(() => import('containers/MotorInsurance'));
const MotorFullQuote = lazy(() => import('containers/MotorInsurance/FullQuote'));
const MotorProductDetails = lazy(() => import('containers/MotorInsurance/ProductDetails'));

const HomeInsurance = lazy(() => import('containers/HomeInsurance'));
const HomeFullQuote = lazy(() => import('containers/HomeInsurance/FullQuote'));
// const HomeProductDetails = lazy(() => import('containers/HomeInsurance/ProductDetails'));

const SmallBusinessInsurance = lazy(() => import('containers/SmallBusinessInsurance'));
const GroupHealthInsurance = lazy(() => import('containers/GroupHealthInsurance'));
const GroupLifeInsurance = lazy(() => import('containers/GroupLifeInsurance'));

const QuoteStore = lazy(() => import('containers/QuoteStore'));
const ProductListing = lazy(() => import('containers/ProductListing'));
const ProductDetails = lazy(() => import('containers/ProductDetails'));
const ProductPayment = lazy(() => import('containers/ProductPayment'));
const Pay = lazy(() => import('containers/Pay'));
const Completed = lazy(() => import('components/Completed'));

function App() {
  return (
    <div className="d-flex flex-column min-vh-100">
      <Router>
        <PageView />
        <Navigation />
        <ErrorBoundary
          as={Suspense}
          fallback={<Interstitial />}
          catchComponent={InternalError}
        >
          <Switch>
            <Route
              path={ROUTES.root}
              stores={['blokStore']}
              component={Homepage}
            />

            {/* MOTOR INSURANCE */}
            <Route
              path={ROUTES.motor.root}
              component={MotorInsurance}
              routes={ROUTES.motor}
              productStore="motorStore"
            />
            <Route
              path={ROUTES.motor.fullQuote}
              component={QuoteStore}
              stage={MotorFullQuote}
              routes={ROUTES.motor}
              productStore="motorStore"
            />
            <Route
              path={ROUTES.motor.listings}
              component={QuoteStore}
              stage={ProductListing}
              routes={ROUTES.motor}
              productStore="motorStore"
              modal={MotorModalContent}
            />
            <Route
              path={ROUTES.motor.productDetails}
              component={QuoteStore}
              stage={ProductDetails}
              customizations={MotorProductDetails}
              routes={ROUTES.motor}
              productStore="motorStore"
              hasPayments
            />
            <Route
              path={ROUTES.motor.productPayment}
              component={QuoteStore}
              stage={ProductPayment}
              routes={ROUTES.motor}
              productStore="motorStore"
            />

            {/* HOME INSURANCE */}
            <Route
              path={ROUTES.home.root}
              component={HomeInsurance}
              routes={ROUTES.home}
              productStore="homeStore"
            />
            <Route
              path={ROUTES.home.fullQuote}
              component={HomeFullQuote}
              routes={ROUTES.home}
              productStore="homeStore"
            />
            <Route
              path={ROUTES.home.listings}
              component={ProductListing}
              routes={ROUTES.home}
              productStore="homeStore"
            />
            {/* <Route
                path={ROUTES.home.productDetails}
                component={ProductDetails}
                customizations={HomeProductDetails}
                routes={ROUTES.home}
                stores={['homeStore']}
                productStore="homeStore"
              /> */}

            {/* BUSINESS INSURANCE */}
            <Route
              path={ROUTES.smallBusiness.root}
              component={SmallBusinessInsurance}
            />
            <Route
              path={ROUTES.groupLife.root}
              component={GroupLifeInsurance}
            />

            <Route
              path={ROUTES.groupHealth.root}
              component={GroupHealthInsurance}
            />

            {/* GENERAL ROUTES */}
            <Route path={ROUTES.pay} component={Pay} exact={false} />
            <Route path={ROUTES.completed} component={Completed} />
            <Route component={NotFound} />
          </Switch>
        </ErrorBoundary>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
