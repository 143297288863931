function yearsOfManufacture(minDate = 1990, maxDate = new Date().getFullYear() + 1) {
  const values = [];

  for (let i = maxDate; i >= minDate; i--) {
    values.push({ id: i, name: i });
  }

  return values;
}

export default yearsOfManufacture;
