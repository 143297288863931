import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Dropdown as BaseDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import Link from 'components/Link';

function Dropdown({ heading, items }) {
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!isOpen);

  useEffect(() => setOpen(false), [location.pathname]);

  return (
    <BaseDropdown nav inNavbar isOpen={isOpen} toggle={toggle}>
      <DropdownToggle nav caret>
        {heading}
      </DropdownToggle>
      <DropdownMenu right>
        {items.map((i) => {
          if (i.component === 'Divider') {
            return (<DropdownItem key={i._uid} divider />);
          }

          return (
            <Link
              key={i._uid}
              className="dropdown-item text-decoration-none"
              to={i.url}
              caption={i.caption}
              external={i.external}
              newWindow={i.newWindow}
            />
          );
        })}
      </DropdownMenu>
    </BaseDropdown>
  );
}

Dropdown.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Dropdown;
