function initAntiClickjack() {
  if (window.self === window.top) {
    const antiClickjack = document.getElementById('antiClickjack');
    antiClickjack.parentNode.removeChild(antiClickjack);
  } else {
    window.top.location = window.self.location;
  }
}

export default initAntiClickjack;
