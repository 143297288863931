import { mergeWith, isNull, isArray } from 'lodash';

export function mergeData(object, source) {
  // Recursively merge object and source data.
  // Use store value (i.e. default value) if server returns with null value to
  // prevent form inputs from switching between a controlled and uncontrolled
  // component
  return mergeWith(object, source, (objValue, srcValue) => {
    if (isNull(srcValue)) {
      return objValue;
    }

    // Replace arrays instead of recursively merge to treat array as one
    // (e.g. new list of drivers instead of merging)
    if (isArray(srcValue)) {
      return srcValue;
    }
  });
}

export default mergeData;
