/**
 * Fallback component to display during React.Suspense or during quote
 * transition (i.e. loading from localStorage).
 */

import React from 'react';
import { Container, Col, Row } from 'reactstrap';

import Page from 'components/Page';
import Magnify from 'images/magnify.svg';
import { useParams } from 'react-router-dom';


function Interstitial() {
  const { quoteReference } = useParams();
  const title = quoteReference ? 'Loading Your Quote' : 'Loading';
  const caption = quoteReference ? 'Loading Your Quote...' : 'Loading';

  return (
    <Page
      title={title}
      className="d-flex mt-12 mt-md-17 align-items-center"
    >
      <Container>
        <Row className="flex-grow-1 align-content-center my-4 my-md-12">
          <Col className="text-center">
            {quoteReference && (
              <Row>
                <Col className="text-center">
                  <h5>
                    Quote Reference: <span className="purple">{quoteReference}</span>
                  </h5>
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <img src={Magnify} alt="" height="100" />
              </Col>
            </Row>

            <Row className="my-3 my-md-8">
              <Col md={8} className="m-auto">
                <h4 className="font-weight-bolder">{caption}</h4>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Page>
  );
}

export default Interstitial;
