/**
* Flex-support component wrapper for major routes that forces footer at the
* bottom of the page.
*/
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';


// BUG: flex support in IE
function Page({ title, className, children }) {
  useEffect(() => {
    if (title) {
      document.title = `${title} | ${process.env.REACT_APP_SITE_NAME}`;
    } else {
      document.title = process.env.REACT_APP_SITE_NAME;
    }
  });

  return (
    <div className={`mh-100 flex-grow-1 ${className}`}>
      {children}
    </div>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

Page.defaultProps = {
  // Top margins for fixed navigation and bottom margin for footer
  className: 'mt-12 mt-md-17',
  title: null,
};

export default Page;
