import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';

import { GROUP_LIFE, GROUP_HEALTH, SMALL_BUSINESS } from 'api_routes';
import MotorStore from 'stores/motorStore';
import HomeStore from 'stores/homeStore';
import LifeStore from 'stores/lifeStore';
import BusinessStore from 'stores/businessStore';
import StoryblokStore from 'stores/storyblokStore';
import CallRequestStore from 'stores/callRequestStore';
import PayStore from 'stores/payStore';
import initAntiClickjack from 'utils/clickjacking';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

initAntiClickjack();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE, {
  debug: process.env.NODE_ENV === 'development',
  testMode: process.env.NODE_ENV === 'test',
});

ReactDOM.render(
  <Provider
    payStore={new PayStore()}
    motorStore={new MotorStore()}
    homeStore={new HomeStore()}
    lifeStore={new LifeStore()}
    groupLifeStore={new BusinessStore(GROUP_LIFE)}
    groupHealthStore={new BusinessStore(GROUP_HEALTH)}
    smallBusinessStore={new BusinessStore(SMALL_BUSINESS)}
    blokStore={new StoryblokStore()}
    callRequestStore={new CallRequestStore()}
  >
    <App />
  </Provider>, document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
