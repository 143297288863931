import React from 'react';
import { inject, observer } from 'mobx-react';

import STORIES from 'storybloks';
import Cards from 'components/Cards';
import Card from './Card';


function QuickLinks({ blokStore }) {
  blokStore.fetchStory(STORIES.quickLinks);
  const story = blokStore.getBlock(STORIES.quickLinks);
  const items = story('items', []);

  return (
    <Cards
      className="px-6 px-md-0 flex-column flex-lg-row"
      component={Card}
      items={items}
    />
  );
}

export default inject('blokStore')(observer(QuickLinks));
