import { computed, decorate, action } from 'mobx';

import formatCurrency from 'utils/currencyFormatter';

import occupancies from 'data/occupancyTypes';
import typesOfCover from 'data/homeCoverTypes';
import towns from 'data/towns';
import wallMaterials from 'data/wallMaterials';
import roofMaterials from 'data/roofMaterials';
import locations from 'data/propertyLocations';
import booleans from 'data/booleans';
import parishes from 'data/parishes';
import { HOME } from 'api_routes';
import initialValues from 'data/homeInitialValues';

import LookupStore from './lookupStore';
import QuoteStore from './quoteStore';


class HomeStore {
  constructor() {
    this.endpoints = HOME;
    this.lookups = new LookupStore(this, {
      occupancies,
      typesOfCover,
      towns,
      wallMaterials,
      roofMaterials,
      locations,
      parishes,
      booleans,
    });
    this.quote = new QuoteStore(this, initialValues);
  }

  fetchQuickQuoteLookups() {
    return this.lookups.fetch(this.endpoints.LOOK_UPS.QUICK_QUOTE);
  }

  fetchFullQuoteLookups() {
    return this.lookups.fetch(this.endpoints.LOOK_UPS.FULL_QUOTE);
  }

  fetchFullQuote(data) {
    return new Promise((resolve) => resolve(null));
    // return this.quote.fetch(this.endpoints.QUOTES.FULL_QUOTE, data);
  }

  postQuickQuote(data) {
    return this.quote.fetch(this.endpoints.QUOTES.QUICK_QUOTE, data);
  }

  fetchAddOns(data) {
    return this.quote.updateCoverage(this.endpoints.ADD_ONS, data);
  }

  getQuickQuote(data) {
    return this.quote.submit(data);
  }

  confirmQuote() {
    return null;
  }

  get formData() {
    const { data } = this.quote;

    return {
      coverDetails: {
        typeOfCover: data.typeOfCover,
        buildingValue: data.buildingValue,
        contentValue: data.contentValue,
        parish: data.parish,
        town: data.town,
      },
      propertyDetails: {
        occupancyType: data.occupancyType,
        wallMaterial: data.wallMaterial,
        roofMaterial: data.roofMaterial,
        location: data.location,
      },
      personalDetails: {
        recentClaims: data.recentClaims,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
      },
    };
  }

  get quoteSummary() {
    const { requestDetails } = this.quote;

    return [
      { title: 'Building Value', description: '' },
      { title: 'Contents Value', description: '' },
      { title: 'Wall Type', description: '' },
      { title: 'Property Location', description: '' },
      { title: 'Occupancy Type', description: '' },
    ];
  }
}

export default decorate(HomeStore, {
  fetchFullQuote: action,
  fetchQuickQuote: action,
  fetchAddOns: action,
  quoteSummary: computed,
  formData: computed,
});
