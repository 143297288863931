import React from 'react';
import PropTypes from 'prop-types';
import { CardDeck } from 'reactstrap';


function Cards({ className, component, items }) {
  const Component = component;

  return (
    <CardDeck className={className}>
      {items.map(i => <Component key={i._uid} {...i} />)}
    </CardDeck>
  );
}

Cards.defaultProps = {
  className: null,
  items: [],
};

Cards.propTypes = {
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default Cards;
