import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { inject, observer } from 'mobx-react';

import STORIES from 'storybloks';
import NavList from './NavList';


function Footer({ blokStore }) {
  blokStore.fetchStory(STORIES.footer);
  const story = blokStore.getBlock(STORIES.footer);
  const links = story('links', []);
  const copyright = story('copyright', null);

  return (
    <footer className="container-fluid bg-dark text-white">
      <Container className="py-8">
        <Row className="mb-4 mb-md-6">
          {links.map(i => (
            <NavList
              key={i._uid}
              heading={i.heading}
              items={i.items}
            />
          ))}
        </Row>
        <Row>
          <Col className="text-center text-subtle">
            <h5 className="m-0">
              &copy; {`${new Date().getFullYear()} ${copyright}`}
            </h5>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  blokStore: PropTypes.object.isRequired,
};

export default inject('blokStore')(observer(Footer));
