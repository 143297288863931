/**
* MobX store for holding dropdown values for a product category.
*/

import { observable, decorate, action, runInAction } from 'mobx';
import { get, filter, find, orderBy } from 'lodash';

import STATUSES from './constants';


class LookupStore {
  constructor(rootStore, defaultValues = {}) {
    this.rootStore = rootStore;
    this.data = defaultValues;
    this.status = STATUSES.ready;
    this.host = process.env.REACT_APP_API_HOST_URL;
  }

  async fetch(endpoint) {
    /**
    * Fetch field options from api for quick quote form. Response data will
    * override any default values automatically.
    */
    const axios = await import('axios');
    this.status = STATUSES.pending;

    try {
      const response = await axios.get(`${this.host}${endpoint}`);
      runInAction(() => {
        this.status = STATUSES.loaded;
        this.data = { ...this.data, ...response.data };
      });
    } catch (error) {
      runInAction(() => {
        this.status = STATUSES.error;
      });
    }

    return this.data;
  }

  async bulkFetch(endpoints) {
    const axios = await import('axios');
    const requests = endpoints.map(i => axios.get(`${this.host}${i}`));
    this.status = STATUSES.pending;

    try {
      const response = await axios.all(requests);
      const data = response.reduce((results, current) => (
        { ...results, ...current.data }
      ), {});

      runInAction(() => {
        this.data = { ...this.data, ...data };
        this.status = STATUSES.loaded;
      });
    } catch (error) {
      runInAction(() => {
        this.status = STATUSES.error;
      });
    }
  }

  filter(key, criteria, ...order) {
    const values = this.get(key);
    const filteredValues = filter(values, criteria);
    if (order.length === 0) {
      return filteredValues;
    }

    return orderBy(filteredValues, ...order);
  }

  find(optionsKey, objId, objKey = null) {
    // Return option object or value from option object from options key with given id
    const values = this.get(optionsKey);
    const value = find(values, { id: Number(objId) });

    return (objKey) ? get(value, objKey) : value;
  }

  get(key) {
    return get(this.data, key, []);
  }
}

export default decorate(LookupStore, {
  data: observable,
  fetch: action,
  bulkFetch: action,
  get: action,
  filter: action,
  find: action,
});
