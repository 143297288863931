const ROUTES = {
  root: '/',
  pay: '/pay',
  motor: {
    root: '/motor-insurance',
    fullQuote: '/motor-insurance/:quoteReference',
    listings: '/motor-insurance/:quoteReference/full-quote',
    productDetails: '/motor-insurance/:quoteReference/full-quote/:productReference',
    productPayment: '/motor-insurance/:quoteReference/full-quote/:productReference/payment',
    completed: '/motor-insurance/:quoteReference/completed',
  },
  home: {
    root: '/home-insurance',
    fullQuote: '/home-insurance/:quoteReference',
    listings: '/home-insurance/:quoteReference/full-quote',
    productDetails: '/home-insurance/:quoteReference/full-quote/:productReference',
    completed: '/home-insurance/:quoteReference/completed',
  },
  life: {
    root: '/life-insurance',
  },
  termLife: {
    root: '/life-insurance/term-life',
    quote: '/life-insurance/term-life/:quoteReference',
    completed: '/life-insurance/term-life/:quoteReference/completed',
  },
  groupLife: {
    root: '/business-insurance/group-life',
  },
  groupHealth: {
    root: '/business-insurance/group-health',
  },
  smallBusiness: {
    root: '/business-insurance/small-business',
  },
  completed: '/:product/:quoteReference/completed',
};

export default ROUTES;
