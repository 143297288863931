import React from 'react';
import PropTypes from 'prop-types';
import { Col, Nav, NavItem } from 'reactstrap';

import Link from 'components/Link';

function NavList({ heading, items }) {
  if (items.length === 0) {
    return null;
  }

  return (
    <Col sm={8} md={4} className="mb-5">
      <Nav vertical>
        {heading !== '' && (
          <NavItem>
            <h6 className="text-primary font-weight-bold text-uppercase">
              {heading}
            </h6>
          </NavItem>
        )}
        {items.map(i => (
          <NavItem key={i._uid} className="my-1">
            <Link
              to={i.url}
              external={i.external}
              newWindow={i.newWindow}
              className="p-0 text-decoration-none text-white"
            >
              {i.caption}
            </Link>
          </NavItem>
        ))}
      </Nav>
    </Col>
  );
}

NavList.defaultProps = {
  items: [],
};

NavList.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    _uid: PropTypes.string,
    caption: PropTypes.string,
    to: PropTypes.string,
    target: PropTypes.string,
  })),
};

export default NavList;
