import axios from 'axios';
import { computed, observable, decorate, action, runInAction } from 'mobx';


class PayStore {
  // Promise states
  STATES = {
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
    REJECTED: 'rejected',
  }

  constructor() {
    this.state = null;
    this.policyId = null;
    this.nationalId = null;
    this.data = null;
    this.error = null;
    this.host = process.env.REACT_APP_API_HOST_URL;
  }

  async getPolicy(formData) {
    this.state = this.STATES.PENDING;

    try {
      const resp = await axios.post(`${this.host}jamaica/policy`, formData);

      runInAction(() => {
        this.state = this.STATES.FULFILLED;
        this.policyId = formData.policy_id;
        this.nationalId = formData.national_id;
        this.data = resp.data;
        this.data.amount = resp.data.minAmount;
      });
    } catch (error) {
      runInAction(() => {
        this.state = this.STATES.REJECTED;
        this.error = error.response;
      });
    }
  }

  saveCard(sessionId, cardholder = null) {
    this.state = this.STATES.PENDING;
    
    return new Promise((resolve, reject) => {
      axios.put(`${this.host}jamaica/policy`, {
        id: this.data.id,
        policy_id: this.policyId,
        session: sessionId,
        cardholder,
      })
      .then((resp) => {
        runInAction(() => {
          this.state = this.STATES.FULFILLED;
          this.data.sourceOfFunds = resp.data;
        });
        return resolve(resp.data);
      })
      .catch((error) => {
        runInAction(() => {
          this.state = this.STATES.REJECTED;
          this.error = error.response;
        });
        return reject(null);
      });
    });
  }

  pay() {
    this.state = this.STATES.PENDING;

    return new Promise((resolve, reject) => {
      axios.post(`${this.host}jamaica/policy/pay`, {
        id: this.data.id,
        policy_id: this.policyId,
        amount: this.data.amount,
      })
      .then(() => {
        runInAction(() => {
          this.state = this.STATES.FULFILLED;
        });
        return resolve();
      })
      .catch((error) => {
        runInAction(() => {
          this.state = this.STATES.REJECTED;
          this.error = error.response;
        });
        return reject();
      });
    });
  }

  reset() {
    this.state = null;
    this.policy = null;
    this.trn = null;
    this.data = null;
    this.error = null;
  }

  get isReady() {
    return this.state === this.STATES.READY;
  }

  get isPending() {
    return this.state === this.STATES.PENDING;
  }

  get isFulfilled() {
    return this.state === this.STATES.FULFILLED;
  }

  get isRejected() {
    return this.state === this.STATES.REJECTED;
  }

  get isAuthenticated() {
    return this.policyId && this.nationalId && this.data;
  }

  get hasBalance() {
    return this.data && this.data.balance !== '0.00';
  }
}

export default decorate(PayStore, {
  getPolicy: action,
  saveCard: action,
  pay: action,
  reset: action,

  state: observable,
  nationalId: observable,
  policyId: observable,
  data: observable,
  error: observable,

  isReady: computed,
  isPending: computed,
  isFulfilled: computed,
  isRejected: computed,
  isAuthenticated: computed,
  hasBalance: computed,
});
