import { computed, decorate, action } from 'mobx';

import formatCurrency from 'utils/currencyFormatter';
import claimFreeYears from 'data/noClaimsDiscounts';
import driverAges from 'data/driverAges';
import engineSizes from 'data/engineSizes';
import months from 'data/months';
import occupations from 'data/occupations';
import parishes from 'data/parishes';
import makes from 'data/vehicleMakes';
import models from 'data/vehicleModels';
import uses from 'data/vehicleUses';
import yearsOfManufacture from 'data/yearsOfManufacture';
import genders from 'data/genders';
import vehicleTypes from 'data/vehicleTypes';
import booleans from 'data/booleans';
import initialValues from 'data/motorInitialValues';
import { MOTOR } from 'api_routes';

import LookupStore from './lookupStore';
import QuoteStore from './quoteStore';


class MotorStore {
  constructor() {
    this.lookups = new LookupStore(this, {
      claimFreeYears,
      driverAges,
      engineSizes,
      months,
      occupations,
      parishes,
      booleans,
      vehicleTypes,
      genders,
      makes,
      models,
      uses,
      yearsOfManufacture: yearsOfManufacture(),
    });
    this.quote = new QuoteStore(this, initialValues);
    // Get any data from API on load
    this.fetchLookups();
  }

  postQuote(data) {
    return this.quote.post(MOTOR.QUOTE, data);
  }

  putQuote(data) {
    return this.quote.put(MOTOR.QUOTE, data);
  }

  confirmQuote(data) {
    return this.quote.confirm(MOTOR.QUOTE, data);
  }

  fetchLookups() {
    this.lookups.bulkFetch([
      MOTOR.LOOK_UPS.MAKES,
      MOTOR.LOOK_UPS.ENGINES,
      MOTOR.LOOK_UPS.PARISHES,
    ]);
  }

  fetchCoverTypes() {
    this.lookups.fetch(MOTOR.LOOK_UPS.COVER_TYPES);
  }

  fetchModels(makeId) {
    this.lookups.fetch(`${MOTOR.LOOK_UPS.MODELS}/${makeId}`);
  }

  fetchAddOns(data) {
    return this.quote.updateCoverage(this.endpoints.ADD_ONS, data);
  }

  // Format form data into an appropriate request structure
  // eslint-disable-next-line class-methods-use-this
  transformRequestData({ driver, vehicle, additional, additionalDrivers }) {
    const drivers = [
      {
        ...driver,
        licenseIssueDate: additional.licenseIssueDate,
        accidentsInLastThreeYears: additional.accidentsInLastThreeYears,
        licenseSuspended: additional.licenseSuspended,
        insuranceDeclined: additional.insuranceDeclined,
        medicalConditions: additional.medicalConditions,
      }
    ];

    if (additional.additionalDrivers) {
      drivers.push(...additionalDrivers.drivers);
    }

    return {
      drivers,
      vehicles: [vehicle],
      coverStartMonth: additional.coverStartMonth,
      claimFreeYears: additional.claimFreeYears,
    };
  }

  get formData() {
    // Transforms quote.data to a useable structure for form.
    const { drivers, vehicles, ...data } = this.quote.data;
    const primaryDriver = drivers.find(i => i.primary === true);
    const additionalDrivers = drivers.filter(i => i.primary !== true);

    return {
      driver: {
        firstName: primaryDriver.firstName,
        lastName: primaryDriver.lastName,
        dateOfBirth: primaryDriver.dateOfBirth,
        gender: primaryDriver.gender,
        occupation: primaryDriver.occupation,
        parishJamaica: primaryDriver.parishJamaica,
        primary: primaryDriver.primary,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
      },
      vehicle: vehicles[0],
      additional: {
        dateOfBirth: primaryDriver.dateOfBirth,
        licenseIssueDate: primaryDriver.licenseIssueDate,
        accidentsInLastThreeYears: primaryDriver.accidentsInLastThreeYears,
        licenseSuspended: primaryDriver.licenseSuspended,
        insuranceDeclined: primaryDriver.insuranceDeclined,
        medicalConditions: primaryDriver.medicalConditions,
        namedDriversOnly: data.namedDriversOnly,
        claimFreeYears: data.claimFreeYears,
        coverStartMonth: data.coverStartMonth,
        additionalDrivers: drivers.length > 1,
      },
      additionalDrivers: { drivers: additionalDrivers },
    };
  }

  get quoteSummary() {
    const { data, selectedProduct } = this.quote;
    const vehicle = data.vehicles[0];

    const vehicleYear = this.lookups.find(
      'yearsOfManufacture',
      vehicle.yearOfManufacture,
      'name',
    );
    const vehicleUse = this.lookups.find('uses', vehicle.use, 'name');
    const vehicleType = this.lookups.find(
      'vehicleTypes',
      vehicle.vehicleType,
      'name',
    );
    const vehicleMake = this.lookups.find('makes', vehicle.makeJamaica, 'make');
    const vehicleModel = this.lookups.find('models', vehicle.modelJamaica, 'model');
    const engineSize = this.lookups.find('engineSizes', vehicle.engineSize, 'sizeRange');
    const claimFreeYears = this.lookups.find('claimFreeYears', data.claimFreeYears, 'name');

    return [
      {
        title: 'Type of Cover',
        description: selectedProduct.product.coverType.name,
      },
      {
        title: 'Vehicle',
        description: `${vehicleYear} ${vehicleMake} ${vehicleModel} ${engineSize}cc`,
      },
      {
        title: 'Vehicle Type',
        description: vehicleType,
      },
      {
        title: 'Vehicle Use',
        description: vehicleUse,
      },
      {
        title: 'Vehicle Value',
        description: formatCurrency(vehicle.value),
      },
      {
        title: 'No Claims Discount',
        description: claimFreeYears,
      },
    ];
  }
}

export default decorate(MotorStore, {
  postQuote: action,
  putQuote: action,
  fetchLookups: action,
  fetchCoverTypes: action,
  quoteSummary: computed,
  formData: computed,
});
