/* eslint react/no-did-update-set-state: 0 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import {
  Container, Nav, NavbarToggler, Navbar, Collapse, NavItem,
} from 'reactstrap';

import STORIES from 'storybloks';
// import Logo from 'images/logo.png';
import Logo from 'images/billy_craig_logo.png';
import Link from 'components/Link';

import styles from './Navigation.module.scss';
import Dropdown from './Dropdown';

function Navigation({ blokStore }) {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();
  const toggleOpen = () => setOpen(!isOpen);
  useEffect(() => setOpen(false), [location.pathname]);

  blokStore.fetchStory(STORIES.navbar);
  const story = blokStore.getBlock(STORIES.navbar);
  const items = story('items', []);

  return (
    <Navbar
      color="white"
      light
      className={`${styles.container} fixed-top shadow-sm px-6 py-2 py-md-4`}
      expand="md"
      id="site-nav"
    >
      <Container className="pr-0">
        <Link to="/" className="navbar-brand mr-auto">
          <img className={styles.logo} src={Logo} alt={process.env.REACT_APP_SITE_NAME} />
        </Link>
        <NavbarToggler className="border-0" onClick={toggleOpen} />

        <Collapse navbar isOpen={isOpen}>
          <Nav className="ml-auto" navbar>
            {items.map((i) => {
              if (i.items) {
                return (
                  <Dropdown
                    key={i._uid}
                    heading={i.heading}
                    items={i.items}
                  />
                );
              }

              return (
                <NavItem key={i._uid}>
                  <Link
                    to={i.url}
                    caption={i.caption}
                    external={i.external}
                    newWindow={i.newWindow}
                    className="nav-link"
                  />
                </NavItem>
              );
            })}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

Navigation.propTypes = {
  blokStore: PropTypes.object.isRequired,
};

export default inject('blokStore')(observer(Navigation));
