export const MOTOR = {
  LOOK_UPS: {
    MAKES: 'jamaica/lookups/vehiclemakes',
    MODELS: 'jamaica/lookups/vehiclemodels',
    ENGINES: 'jamaica/lookups/enginesizes',
    PARISHES: 'jamaica/lookups/parishes',
    COVER_TYPES: 'jamaica/lookups/motorinsurance/covertypes',
  },
  QUOTE: 'bestquote/api/insurance/motorquote',
  ADD_ONS: '/motor/addfeatures',
};

export const HOME = {};

export const LIFE = {
  LOOK_UPS: '/life/lookups',
  QUOTE: '/life/quickquote',
};

export const GROUP_LIFE = {
  QUOTE: '/business/group-life',
};

export const GROUP_HEALTH = {
  QUOTE: '/business/group-health',
};

export const SMALL_BUSINESS = {
  QUOTE: '/business/small-business',
};

export const CALL_REQUEST = '/call_request';
