// Constants to track meaningful store states; used for UI control

// TODO: simplify and update
const STATUSES = {
  loaded: 'LOADED', // Quote successfully loaded from localStorage
  error: 'ERROR', // API error
  notFound: 'NOT_FOUND', // Quote reference could not be found in localStorage
  pending: 'PENDING', // API request in progress
  posted: 'POSTED', // API post action
  updated: 'UPDATED', // API put/patch action
  success: 'SUCCESS',
  completed: 'COMPLETED',
  ready: 'READY', // Store state with initial values and no quote or product loaded
};

export default STATUSES;
