import { decorate, action, observable, runInAction } from 'mobx';

import { LIFE } from 'api_routes';
import genders from 'data/genders';
import booleans from 'data/booleans';
import ages from 'data/ages';

import LookupStore from './lookupStore';
import QuoteStore from './quoteStore';


class LifeStore {
  constructor() {
    this.endpoints = LIFE;
    this.lookups = new LookupStore(this, {
      ages,
      genders,
      booleans,
    });
    this.quote = new QuoteStore(this);
  }

  data = {
    coverAmount: '',
    plan: '',
    age: '',
    gender: '',
    smoker: '',
    firstName: '',
    phoneNumber: '',
    emailAddress: '',
  };

  getLookups() {
    return this.lookups.fetch(this.endpoints.LOOK_UPS);
  }

  __getQuote() {
    return new Promise(resolve => resolve({ quoteReference: '1JZ280BHP' }));
  }

  getQuote(formValues) {
    this.pending = true;

    try {
      const data = this.__getQuote();
      runInAction(() => {
        this.data = data;
        this.pending = false;
      });
    } catch {
      runInAction(() => {
        this.pending = false;
        this.error = true;
      });
    }

    return this.data;
  }

  confirmQuote() {
    return new Promise((resolve, reject) => {
      return resolve(null);
    });
  }
}

export default decorate(LifeStore, {
  getLookups: action,
  getQuote: action,
  confirmQuote: action,
  data: observable,
});
