/**
 * Wrapper for persisting MobX quote store observables to localStorage.
 */

class Persist {
  constructor(key) {
    this.key = key;
  }

  load() {
    const payload = window.localStorage.getItem(this.key);
    return JSON.parse(payload || '{}');
  }

  overwrite(data) {
    const payload = JSON.stringify(data);
    window.localStorage.setItem(this.key, payload);
  }

  set(key, payload) {
    const data = this.load();
    data[key] = payload;
    return this.overwrite(data);
  }

  get(key) {
    const data = this.load();
    return data[key];
  }

  delete(key) {
    const data = this.load();
    delete data[key];
    return this.overwrite(data);
  }
}

export default Persist;
