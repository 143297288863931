/**
* General component for handling errors in child components.
*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';


class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { error: false };
  }

  componentDidUpdate(prevProps) {
    /**
     * Reset display of catchComponent on route change otherwise it remains
     * rendered on location changes.
    */
    const { error } = this.state;
    const { location } = this.props;

    if (location !== prevProps.location && error) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ error: false });
    }
  }

  componentDidCatch(error, info) {
    const { log } = this.props;

    this.setState({ error: true });

    if (log) {
      Sentry.captureException(error, info);
    }
  }

  render() {
    const { error } = this.state;
    const { as, catchComponent, children, render, ...props } = this.props;

    if (error) {
      return render ? render() : React.createElement(catchComponent);
    }

    return as ? React.createElement(as, props, children) : children;
  }
}

ErrorBoundary.propTypes = {
  as: PropTypes.elementType, // Element to wrap children
  children: PropTypes.node.isRequired,
  catchComponent: PropTypes.elementType, // Component to render when error occurs
  log: PropTypes.bool,
  render: PropTypes.func, // Alternative to component for render on error
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

ErrorBoundary.defaultProps = {
  as: null,
  catchComponent: null,
  log: true,
  render: null,
};

export default withRouter(ErrorBoundary);
