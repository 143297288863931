/**
 * Component for handling internal and external links from Storyblok.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouteLink } from 'react-router-dom';

function Link({ to, children, caption, className, external, newWindow }) {
  const target = newWindow ? '_blank' : null;
  const rel = external ? 'noopener noreferrer' : null;

  if (external) {
    return (
      <a href={to} className={className} target={target} rel={rel}>
        {children || caption}
      </a>
    );
  }

  return (
    <RouteLink to={to} className={className}>
      {children || caption}
    </RouteLink>
  );
}

Link.defaultProps = {
  external: false,
  newWindow: false,
  className: null,
  children: null,
  caption: null,
};

Link.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  caption: PropTypes.string,
  external: PropTypes.bool,
  newWindow: PropTypes.bool,
};

export default Link;
