/**
 * MobX store for persisting quote application with localStorage and calling API.
 */

import axios from 'axios';
import { observable, decorate, action, computed, runInAction } from 'mobx';
import { filter, find } from 'lodash';

import Persist from './persist';

import STATUSES from './constants';
import mergeData from './utils';

class QuoteStore {
  persist = new Persist('compare-ja.quotes');

  constructor(rootStore, initialData = {}) {
    this.rootStore = rootStore;
    // Keep initially passed data so that store can be reset
    this.initialData = initialData;
    // Request and response data
    this.data = initialData;
    // Used to sync quote data with localStorage
    this.reference = null;
    this.productReference = null;
    // Helper to control UI flow
    this.status = STATUSES.ready;
    // Display only products of this coverType
    this.filterBy = 1;
    this.host = process.env.REACT_APP_API_HOST_URL;
  }

  get sortedProducts() {
    // Return list of quote products sorted by price lowest to highest
    return this.filteredProducts.sort((a, b) => a.annualPremium - b.annualPremium);
  }

  get filteredProducts() {
    // Return list of products by cover type if set, else returns all.
    if (this.filterBy === null) {
      return this.responseData.quotes || [];
    }
    return filter(this.data.quotes, ['product.coverType.id', this.filterBy]);
  }

  get filteredErrors() {
    if (this.filterBy === null) {
      return this.responseData.errors || [];
    }
    return filter(this.data.errors, ['coverType', this.filterBy]);
  }

  get referredErrors() {
    return filter(this.data.errors, 'referred', true);
  }

  get selectedProduct() {
    return find(this.data.quotes, ['id', Number(this.productReference)]);
  }

  load(reference = null) {
    // Avoid reloading if passed reference is already loaded.
    if (reference === this.reference && this.status === STATUSES.loaded) {
      return null;
    }

    const data = this.persist.get(reference || this.reference);

    if (data) {
      this.reference = reference || this.reference;
      this.data = data;
      this.status = STATUSES.loaded;
    } else {
      this.status = STATUSES.notFound;
    }

    return null;
  }

  cleanup(deletePersisted = true) {
    if (deletePersisted) {
      this.persist.delete(this.reference);
    }

    this.reference = null;
    this.productReference = null;
    this.data = this.initialData;
    this.status = STATUSES.ready;
    return null;
  }

  async post(url, formData) {
    this.status = STATUSES.pending;

    try {
      const response = await axios.post(`${this.host}${url}`, formData);

      return runInAction(() => {
        this.reference = response.data.quoteReference;
        this.data = mergeData(this.data, response.data);
        this.persist.set(this.reference, this.data);
        this.status = STATUSES.posted;
        return Promise.resolve(this.data);
      });
    } catch (error) {
      return runInAction(() => {
        this.status = STATUSES.error;
        return Promise.reject(error);
      });
    }
  }

  async put(url, formData) {
    this.status = STATUSES.pending;

    try {
      const { errors, quotes, ...data } = mergeData(this.data, formData);
      const response = await axios.put(`${this.host}${url}`, data);

      return runInAction(() => {
        this.data = mergeData(this.data, response.data);
        this.persist.set(this.reference, this.data);
        this.status = STATUSES.updated;
        return Promise.resolve(this.data);
      });
    } catch (error) {
      return runInAction(() => {
        this.status = STATUSES.error;
        return Promise.reject(error);
      });
    }
  }

  async personalize(formData) {
    return new Promise(resolve => resolve(null));
  }

  async confirm(url, productReference) {
    this.status = STATUSES.pending;
    const data = {
      pk: this.data.pk,
      quoteReference: this.reference,
      phoneNumber: this.data.phoneNumber,
      emailAddress: this.data.emailAddress,
      selectedQuote: Number(productReference),
      completed: true,
    };

    if (this.data.paymentMethod) {
      data.paymentMethod = this.data.paymentMethod;
    }

    try {
      const response = await axios.put(`${this.host}${url}`, data);

      return runInAction(() => {
        this.cleanup();
        return Promise.resolve(response.data);
      });
    } catch (error) {
      return runInAction(() => {
        this.status = STATUSES.error;
        return Promise.reject(error);
      });
    }
  }
}

export default decorate(QuoteStore, {
  reference: observable,
  productReference: observable,
  data: observable,
  filterBy: observable,
  status: observable,

  post: action,
  patch: action,
  personalize: action,
  confirm: action,

  filteredProducts: computed,
  filteredErrors: computed,
  referredErrors: computed,
  sortedProducts: computed,
  selectedProduct: computed,
});
