/**
 * Storyblok story slugs.
 */

const STORIES = {
  homepage: 'homepage',
  navbar: 'navbar',
  quickLinks: 'quick-links',
  whyCompareHorizontal: 'why-compare-horizontal',
  whyCompareVertical: 'why-compare-vertical',
  footer: 'footer',
  services: 'services',
  promises: 'promises',
  homeInsurance: 'insurance/home',
  motorInsurance: 'insurance/motor',
  groupHealth: 'insurance/group-health',
  groupLife: 'insurance/group-life',
  smallBusiness: 'insurance/small-business',
  quoteCompleted: 'quote-completed',
  paymentSelector: 'payment-selector',
  paymentDetails: 'pay',
};

export default STORIES;
