import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';


function CategoryCard({ icon, caption, url }) {
  return (
    <Link to={url} className="card shadow-sm mb-2 mb-lg-0 px-3 py-2 p-md-3 p-md-4 text-decoration-none">
      <Row className="text-left text-sm-center align-items-center flex-grow-1" noGutters>
        <Col xs={3} sm={2} lg={16}>
          <i className={`${icon.type} ${icon.icon} text-secondary font-size-38`} />
        </Col>
        <Col xs="auto" lg={16} className="ml-4 ml-lg-0">
          <h5 className="font-weight-bolder m-0 font-size-sm-20">
            {caption}
          </h5>
        </Col>
      </Row>
    </Link>
  );
}

CategoryCard.propTypes = {
  url: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  caption: PropTypes.string.isRequired,
};

export default CategoryCard;
